const fetchByUrl = (url: string): Promise<string | ArrayBuffer> => {
    return fetch(url, {
        method: 'get',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
    })
        .then((res) => res.blob())
        .then(
            (blob) =>
                new Promise((resolve) => {
                    const reader = new FileReader();

                    reader.onloadend = () => resolve(reader.result || url);

                    reader.readAsDataURL(blob);
                })
        );
};

export const convertUrlToBase64 = async (url: string): Promise<string | ArrayBuffer> => {
    try {
        const base64 = await fetchByUrl(url);

        return base64;
    } catch (error) {
        return url;
    }
};
