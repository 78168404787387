import React from 'react';
import classNames from 'classnames/bind';

import styles from './Image.module.css';

interface ImageSource {
    media?: string;
    srcSet: string;
    type: string;
}

const cx = classNames.bind(styles);

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    sources?: Array<ImageSource>;
    classNameImg?: string;
}

// TODO: Move to libComponents
const Image = ({
    className,
    classNameImg = '',
    sources,
    alt,
    loading = 'eager',
    src,
    srcSet,
    ...other
}: ImageProps) => {
    /**
     * Workaround for https://github.com/facebook/react/issues/20682
     * To prevent loading images twice in Safari let's add src & srcSet attributes after img element creation
     */
    const refCallback = React.useCallback<React.RefCallback<HTMLImageElement>>(
        (node) => {
            if (node) {
                if (src) {
                    node.src = src;
                }

                if (srcSet) {
                    node.srcset = srcSet;
                }
            }
        },
        [src, srcSet]
    );

    const classNameImgCustom = cx('image', classNameImg);

    if (!sources) {
        return <img className={classNameImgCustom} ref={refCallback} alt={alt} loading={loading} {...other} />;
    }

    return (
        <picture className={className}>
            {sources.map(({ srcSet, ...rest }) => (
                <source key={srcSet} srcSet={srcSet} {...rest} />
            ))}
            <img className={classNameImgCustom} ref={refCallback} alt={alt} loading={loading} {...other} />
        </picture>
    );
};

export { Image };
